import React, {useState} from 'react';
import moment from 'moment';
import {useSelectedCompetence} from './slices/selectors/selectDashboardCompetence'
import {useSetCompetence} from './slices/callbacks/useSetDashboardCompetence';
import {DashboardTemplate} from '../../../templates/DashboardTemplate';
import {useGetDashboardIndicatorsQuery} from '../../../services/dashboard/dashboard.service';

import {DashboardSection} from './DashboardLayout';
import {
  DashboardIndicators,
  DashboardSuspicions,
  DashboardStatistics,
} from './DashboardStat';
import {
  DashboardPatientCharts,
  DashboardInfectionCharts,
  DashboardSuspicionCharts,
} from './DashboardCharts';

import {Grid} from '@material-ui/core';

import {LoggedUserCompaniesForm} from "../../../components/organisms/LoggedUserCompaniesForm"

export const Dashboard = () => {
  const competence = useSelectedCompetence();
  const setCompetence = useSetCompetence();

  const {
    data: indicators,
    isFetching: isIndicatorsLoading,
  } = useGetDashboardIndicatorsQuery();

  const getInitialDate = (competence) => {
    const initialDate = indicators?.find((item) => item.key === 'mes_inicial');
    if (competence && initialDate) {
      return moment(initialDate[competence]).format('DD/MM/YYYY');
    }
  }

  const getFinalDate = (competence) => {
    const finalDate = indicators?.find((item) => item.key === 'mes_final');
    if (competence && finalDate) {
      return moment(finalDate[competence]).format('DD/MM/YYYY');
    }
  }

  const COMPETENCES_OPTIONS = !isIndicatorsLoading && [
    {
      value: 'oneMonth',
      label: `Um mês (${getInitialDate('oneMonth')} - ${getFinalDate('oneMonth')})`,
    },
    {
      value: 'threeMonths',
      label: `3 meses (${getInitialDate('threeMonths')} - ${getFinalDate('threeMonths')})`,
    },
    {
      value: 'sixMonths',
      label: `6 meses (${getInitialDate('sixMonths')} - ${getFinalDate('sixMonths')})`,
    },
    {
      value: 'twelveMonths',
      label: `12 meses (${getInitialDate('twelveMonths')} - ${getFinalDate('twelveMonths')})`,
    },
    {
      value: 'all',
      label: `Tudo (${getInitialDate('all')} - ${getFinalDate('all')})`,
    },
  ];

  const handleChangeCompetence = (event) => {
    const {value} = event.target;

    setCompetence(value)
  }

  return (

    !!competence ? (
      <Grid container>
        <DashboardTemplate
          competencesOptions={COMPETENCES_OPTIONS}
          selectedCompetence={competence}
          handleChangeCompetence={handleChangeCompetence}
          extraActions={[<LoggedUserCompaniesForm></LoggedUserCompaniesForm>]}
          title="Dashboard"
          info="Dashboard com as médias do mês anterior."
          divider
        >

          <DashboardSection>
            <DashboardIndicators
              indicators={indicators}
              isIndicatorsLoading={isIndicatorsLoading}
            />
          </DashboardSection>
          <DashboardSection>
            <DashboardSuspicions
              indicators={indicators}
              isIndicatorsLoading={isIndicatorsLoading}
            />
          </DashboardSection>
          <DashboardSection title="Estatísticas">
            <DashboardStatistics
              indicators={indicators}
              isIndicatorsLoading={isIndicatorsLoading}
            />
          </DashboardSection>
          <DashboardSection title="Dados demográficos (todos os pacientes)">
            <DashboardPatientCharts
              indicators={indicators}
              isIndicatorsLoading={isIndicatorsLoading}
            />
          </DashboardSection>
          <DashboardSection title="Infecções confirmadas x não confirmadas">
            <DashboardInfectionCharts
              indicators={indicators}
              isIndicatorsLoading={isIndicatorsLoading}
            />
          </DashboardSection>
          <DashboardSection title="Suspeitas por probabilidade de infecção">
            <DashboardSuspicionCharts
              indicators={indicators}
              isIndicatorsLoading={isIndicatorsLoading}
            />
          </DashboardSection>
        </DashboardTemplate>
      </Grid>
    ) : <div></div>

  );
};
