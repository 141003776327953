/**
 * Enumerador de Exames do tipo cultura
 *
 * Exames do tipo cultura são identificados com este enumerador
 *
 * @constant
 * @type {{ 'HEMOCULTURA': 'HEMOCULTURA', 'CULTURA QUANTITATIVA': 'CULTURA QUANTITATIVA', 'BACTERIOLOGICO': 'BACTERIOLOGICO', 'BACTERIOSCOPIA': 'BACTERIOSCOPIA', 'UROCULTURA': 'UROCULTURA',  }}
 */
export const cultureExamsEnum = {
    'HEMOCULTURA': 'HEMOCULTURA',
    'CULTURA QUANTITATIVA': 'CULTURA QUANTITATIVA',
    'BACTERIOLOGICO': 'BACTERIOLOGICO',
    'BACTERIOSCOPIA': 'BACTERIOSCOPIA',
    'UROCULTURA': 'UROCULTURA',
    'CULTURA DE VIGILANCIA': 'CULTURA DE VIGILANCIA',
    'CLOSTRIDIUM': 'CLOSTRIDIUM',
    'CULTURA': 'CULTURA',
    'CULTURA E ANTIBIOGRAMA-ABCESSO 2ª AMOSTRA': 'CULTURA E ANTIBIOGRAMA-ABCESSO 2ª AMOSTRA'
}

/**
 * Lista de culturas
 *
 * @constant
 * @type {['HEMOCULTURA', 'CULTURA QUANTITATIVA', 'BACTERIOLOGICO', 'BACTERIOSCOPIA', 'UROCULTURA', 'CULTURA DE VIGILANCIA', ]}
 */
export const cultureExamsList = Object.keys(cultureExamsEnum)

// Ordenação de exames pelo atributo 'exame'
export const TopExamsSortEnum = {
  'HEMOCULTURA': 0,
  'UROCULTURA': 1,
  'CULTURA QUANTITATIVA': 2,
  'BACTERIOLOGICO': 3,
  'BACTERIOSCOPIA': 4,
  'CULTURA DE VIGILANCIA': 5,
  'CORONAVIRUS 2019 DETECCAO POR PCR (COVID19)': 6,
  'ANTIGENO DO COVID': 7,
  'HEMOGRAMA': 8,
  'PROTEINA C REATIVA': 9,
  'EQU': 10,
  'GASOMETRIA ARTERIAL': 11,
  'CREATININA': 12,
  'CULTURA': 13,
  // demais exames
  '[DEFAULT]': 14,
}
export const TopExamsSortEnumKeys = Object.keys(TopExamsSortEnum)

// Ordenação de exames pelo atributo 'item_exame'
export const SubExamsSortEnum = {
  // Itens de exames de cultura
  'MATERIAL': 0,
  'CULTURA': 1,
  'BACTERIOLÓGICO': 2,
  'BACTERIOSCÓPICO': 3,
  'OBSERVACAO DO MATERIAL': 4,
  'PERFIL DE RESISTENCIA': 5,
  // Itens do exame: Hemograma
  'ERITRÓCITOS': 0,
  'HEMOGLOBINA': 1,
  'HEMATÓCRITO': 2,
  'LEUCÓCITOS': 3,
  'NEUTRÓFILOS': 4,
  'BASTÕES': 5,
  'MIELÓCITOS': 6,
  'MIELÓCITOS PERCENTUAL': 7,
  'PROMIELÓCITOS': 8,
  'PROMIELÓCITOS PERCENTUAL': 9,
  'METAMIELÓCITOS': 10,
  'METAMIELÓCITOS PERCENTUAL': 11,
  'EOSINÓFILOS': 12,
  'EOSINÓFILOS PERCENTUAL': 13,
  'BASÓFILOS': 14,
  'BASÓFILOS PERCENTUAL': 15,
  'LINFÓCITOS': 16,
  'LINFÓCITOS PERCENTUAL': 17,
  'MONÓCITOS': 18,
  'MONÓCITOS PERCENTUAL': 19,
  'PLAQUETAS': 20,
  'PLAQUETAS PERCENTUAL': 21,
  // demais exames
  '[DEFAULT]': 22
}

/** 
 * Tipos de exame
 * @type {{'LEUCÓCITOS': 'LEUCÓCITOS'}}
 */
 export const examsTypeEnum = {
  'LEUCÓCITOS': 'LEUCÓCITOS'
}

export const examsReferenceValues = {
  'NEUTROPENIA': 500
}
