import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { keycloakConnect } from '../keycloak'

export const baseQuery = retry(fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,

  prepareHeaders: (headers, { getState }) => {
    const selectedCompanyId = localStorage.getItem("selectedCompanyId");

    const companies = selectedCompanyId;

    if (keycloakConnect.token) {
      // headers.set('authorization', `Bearer ${keycloakConnect.token}`)
      headers.set('Authorization', `Bearer ${keycloakConnect.token}`);
    }

    if (companies && companies.length) {
      headers.set("companies-ids", selectedCompanyId);
    }

    return headers
  },

}), { maxRetries: 2 })

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if(!keycloakConnect.token){
    await keycloakConnect.login();
  }
  if(keycloakConnect.isTokenExpired(30)){
    await keycloakConnect.updateToken();
  }
  if (result.error && result.error.status === 403) {
    // try to get a new token
    // await keycloakConnect.login();
    await keycloakConnect.updateToken();
  }
  return result
}
